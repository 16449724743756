$(document).on('turbolinks:load', function() {
  $('#js-survey-questions').hide();
  $('#js-survey-answer-keys').hide();

  $('#js-show-survey-questions').click(function() {
    $('#js-survey-questions').toggle('slow');
  });
  $('#js-show-survey-answer-keys').click(function() {
    $('#js-survey-answer-keys').toggle('slow');
  });
});
